
.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}






/* BELL */
.animated{
  -webkit-animation: ring 2s linear infinite;
  animation: ring 3s linear infinite;
  color:#fff;
}


@keyframes ring {
  0% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    transform: scale(1.7);
    color:#ffcf07;
  }

  2% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    transform: scale(1.7);
    color:#ffcf07;
  }

  4% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transform: scale(1.5);
    color:#ffcf07;
  }

  6% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transform: scale(1.5);
    color:#ffcf07;
  }

  8% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transform: scale(1.3);
    color:#ffcf07;
  }

  10% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transform: scale(1.5);
    color:#ffcf07;
  }

  12% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    transform: scale(1.7);
    color:#ffcf07;
  }

  14% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transform: scale(1.5);
    color:#ffcf07;
  }

  16% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transform: scale(1.3);
    color:#ffcf07;
  }

  18% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transform: scale(1);
    color:#fff;
  }


}
